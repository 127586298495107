@tailwind components;
@tailwind utilities;

@import "assets/style/app";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat" !important;
}

aside .bg-sidebar {
  color: #6f7c92 !important;
}

:where(.css-wko6we).ant-menu-light .ant-menu-item a,
:where(.css-wko6we).ant-menu-light>.ant-menu .ant-menu-item a {
  color: #6f7c92;
}

:where(.css-wko6we).ant-menu .ant-menu-item .ant-menu-item-icon,
:where(.css-wko6we).ant-menu .ant-menu-submenu-title .ant-menu-item-icon {
  color: #6f7c92;
}

:where(.css-wko6we).ant-menu-light .ant-menu-item a:hover,
:where(.css-wko6we).ant-menu-light>.ant-menu .ant-menu-item a:hover {
  color: white
}

aside .bg-sidebar ul li:hover,
aside .bg-sidebar ul.ant-menu li.ant-menu-item-selected {
  svg {
    color: white
  }

  .ant-menu-title-content {
    a {
      color: white
    }
  }
}







:where(.css-1wkuv9c).ant-menu .ant-menu-item .ant-menu-item-icon,
:where(.css-1wkuv9c).ant-menu .ant-menu-submenu-title .ant-menu-item-icon,
:where(.css-1wkuv9c).ant-menu-light .ant-menu-item a,
:where(.css-1wkuv9c).ant-menu-light>.ant-menu .ant-menu-item a,
:where(.css-1wkuv9c).ant-menu-light .ant-menu-item a:hover,
:where(.css-1wkuv9c).ant-menu-light>.ant-menu .ant-menu-item a:hover {
  color: white !important;
}


:where(.css-dev-only-do-not-override-qi43ln).ant-select .ant-select-arrow {
  color: #50B848 !important;
  font-size: 17px !important;
}

h4.ant-typography.css-dev-only-do-not-override-qi43ln {
  color: #fff !important;
  font-weight: 100;
}

// :where(.css-qi43ln).ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
// .ant-select.ant-select-in-form-item.css-qi43ln.ant-select-single.ant-select-show-arrow {
//   height: 50px !important;
// }

:where(.css-qi43ln).ant-select .ant-select-arrow {
  color: #50B848 !important;
  font-size: 14px !important;
  margin-left: 5px !important;
}


span.anticon.anticon-down.ant-select-suffix,
:where(.css-qi43ln).ant-select .ant-select-arrow>* {
  color: #6cb557 !important;
}

.ant-empty-description {
  color: white !important;
}

.ant-select-dropdown.css-qi43ln.ant-select-dropdown-placement-bottomLeft {
  border: 2px solid rgb(40, 53, 73) !important;
  background: rgb(12, 17, 26) !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
  background: #283549 !important;
}


.ant-select-dropdown.css-qi43ln.ant-select-dropdown-placement-bottomLeft,
:where(.css-rj3ynn).ant-select-dropdown {
  border: 2px solid rgb(40, 53, 73) !important;
  background: rgb(12, 17, 26) !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected,
:where(.css-rj3ynn).ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #283549 !important;
}

span.ant-select-selection-item {
  color: hsla(0, 0%, 100%, .7) !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background: none !important;
}


aside .bg-sidebar,
li.ant-menu-item {
  color: #6f7c92 !important;
}

// Colours
.green-color {
  color: $primary-color;
}

.yellow-color {
  color: $yellow-color;
}

.red-color {
  color: $red-color;
}

.green-background-color {
  background-color: $primary-color;
}

.purple-color {
  color: $secondary-color !important;
}

.purple-background-color {
  background-color: $secondary-color;
}

.notInvited {
  color: #ff4f4f !important;
}

.invitationSent {
  color: #ecb738 !important;
}

.completed {
  color: #50b848 !important;
}



.genrate_invoice_btn {
  padding: 11px 10px;
  background: #283549;
  border: 1px solid;
  color: white;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  font-family: "Montserrat" !important;
  text-transform: capitalize;
}


button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed,
button.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
  padding: 13px;
}

.ant-table-row-expand-icon {
  margin-bottom: 27px;
}




button.ant-table-row-expand-icon.ant-table-row-expand-icon-collapsed {
  &::before {
    right: 3px !important;
    top: 12px !important;
  }

  &::after {
    transform: rotate(0deg);
    left: 12.5px !important;
  }
}

button.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
  &::before {
    top: 11px;
  }

  &::after {
    left: 12px;
    top: 1px;
  }
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #485a75 !important;
}

:where(.css-1wggqpc).ant-select-dropdown,
:where(.css-3sfogc).ant-select-dropdown,
.ant-select-dropdown-placement-topLeft,
:where(.css-yig2mz).ant-select-dropdown {
  border: 2px solid rgb(40, 53, 73) !important;
  background: rgb(12, 17, 26) !important;
}

aside.ant-layout-sider.ant-layout-sider-light.fixed.h-full.shadow-md.z-50.css-yig2mz {
  background-color: #161e2b !important;
}
