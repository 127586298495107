.mobileHumberger {
  color: white;
  margin-right: 20px;
  font-size: 20px;
}

.hide {
  display: none;
}

.label-color label {
  color: gray !important;
}

.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #6cb557 !important;
}

.ant-form-item-control-input-content>span,
.ant-form-item-control-input-content>button {
  border-radius: 99999px !important;
}

.ant-result-title,
.ant-result-subtitle {
  color: white !important;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: 100%;
}

.ant-table-content,
.ant-table {
  background: transparent !important;
}

table {
  background: transparent !important;
  color: hsla(0, 0%, 100%, 0.7);

  .ant-table-thead {
    background: rgba(24, 34, 50, .902);
  }

  tbody {
    .ant-table-placeholder {
      background: transparent !important;
    }

    .ant-table-placeholder .ant-empty-description {
      color: #fff;
    }
  }

  tr {
    th {
      background: rgba(24, 34, 50, 0.9) !important;
    }

    td.ant-table-column-sort {
      background: transparent !important;
    }

    td,
    th {
      border-bottom: 2px solid rgba(24, 34, 50, 0.9) !important;
      color: hsla(0, 0%, 100%, 0.7) !important;
      background: transparent !important;
    }
  }
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon,
.ant-input-clear-icon,
.ant-pagination-item-link,
.ant-table-column-sorter,
.ant-pagination-item-ellipsis,
.ant-select-arrow,
.ant-picker-suffix {
  color: #fff !important;
}

.ant-table-row-expand-icon {
  color: #000 !important;
}

.anticon.anticon-logout svg {
  color: #000;
}

.anticon.active svg {
  color: #6cb557;
}

.transition-all .ant-btn {
  color: #fff !important;
}

.ant-form-item-label>label {
  color: #fff !important;
}

.ant-divider-with-text {
  color: #fff !important;
  border-block-start: #fff !important;
}

.ant-input-affix-wrapper-lg,
.ant-input-number,
.ant-input-number-input .ant-picker {
  border-radius: 25px !important;
  border: 2px solid #283549 !important;
  background: rgba(24, 34, 50, 0.9) !important;
  color: hsla(0, 0%, 100%, 0.7) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 50px !important;
  padding: 6px 20px 10px !important;
}

.ant-input-affix-wrapper>input.ant-input,
.ant-input-number,
.ant-picker {
  background: transparent !important;
  color: #fff;
  -webkit-box-shadow: 0 0 0 50px #182131 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.ant-input-affix-wrapper>input.ant-input:focus,
.ant-input-number:focus,
.ant-picker:focus {
  -webkit-box-shadow: 0 0 0 50px #182131 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.ant-input-number-input {
  color: #fff !important;
}

.categorytable>div {
  border: 2px solid #283549;
  border-radius: 10px !important;
}

.ant-input-number-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.category-form .ant-input,
.ant-input-number,
.ant-picker,
.ant-input-affix-wrapper {
  border-radius: 25px;
  border: 2px solid #283549;
  background: rgba(24, 34, 50, 0.9) !important;
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  padding: 6px 20px 10px;
}

.category-form.brand-detail-form .ant-input {
  height: auto;
}

aside .bg-sidebar {
  color: #6f7c92 !important;
}

aside .bg-sidebar ul li:hover {
  color: #fff !important;
  background: #283549 !important;
}

aside .bg-sidebar ul.ant-menu li.ant-menu-item-selected {
  color: #fff !important;
  background: #283549 !important;
}

aside .bg-sidebar ul li .ant-menu-title-content .hidden .ant-btn {
  background-color: #6cb557;
  border: 1px solid #6cb557;
  padding: 0px 4px;
}

.pagination .ant-pagination li {
  background: #283549 !important;
  margin: 0 5px !important;
  border-radius: 6px;
}

.pagination .ant-pagination .ant-pagination-item a {
  color: #fff;
}

.pagination .ant-pagination li.ant-pagination-total-text {
  background-color: transparent !important;
}

.pagination .ant-pagination .ant-pagination-item.ant-pagination-item-active a {
  color: #fff;
  background-color: #6cb557;
}

.pagination .ant-pagination .ant-select-selector {
  color: #fff !important;
  background: #283549 !important;
  border: none;
  cursor: pointer !important;
}


.ant-input[disabled] {
  color: rgba(255, 255, 255, 0.5);
}

.ant-radio-wrapper .ant-radio-inner {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  background: transparent;
}

.ant-radio-wrapper span.ant-radio+* {
  color: #fff;
}

:where(.css-1afxvbo).ant-menu-light .ant-menu-item,
:where(.css-1afxvbo).ant-menu-light>.ant-menu .ant-menu-item,
:where(.css-1afxvbo).ant-menu-light .ant-menu-submenu-title,
:where(.css-1afxvbo).ant-menu-light>.ant-menu .ant-menu-submenu-title {
  color: inherit !important;
}

:where(.css-1afxvbo).ant-input-outlined:hover {
  background-color: inherit !important;
}

.brand_table {
  th {
    width: 14rem;

    &:first-child {
      width: 5rem;
    }

    &:last-child {
      width: 5rem;
    }

  }
}

:where(.css-dev-only-do-not-override-qi43ln).ant-select-dropdown {
  border: 2px solid rgb(40, 53, 73) !important;
  background: rgb(12, 17, 26) !important;
  // color: rgba(255, 255, 255, 0.7) !important;
}

.category_dropdown {

  .ant-select-selector {
    border-radius: 25px;
    border: 2px solid #283549 !important;
    background: rgba(24, 34, 50, 0.9) !important;
    color: hsla(0, 0%, 100%, 0.7);
    font-size: 16px;
    font-weight: 600;
    box-shadow: none !important;

    &:hover {
      border-color: #d9d9d9 !important;
      /* Change to your desired hover color */
    }
  }

  .ant-select.css-dev-only-do-not-override-qi43ln.ant-select-single.ant-select-show-arrow.ant-select-open,
  .ant-select.css-dev-only-do-not-override-qi43ln.ant-select-single.ant-select-show-arrow {
    width: 100%;
    height: 50px;
  }

  :where(.css-dev-only-do-not-override-qi43ln).ant-select-single.ant-select-open .ant-select-selection-item {
    color: hsla(0, 0%, 100%, 0.7) !important;
  }

}

:where(.css-dev-only-do-not-override-qi43ln).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: none !important;
}

.ant-select-item-option-content {
  color: hsla(0, 0%, 100%, 0.7) !important;
}

:where(.css-dev-only-do-not-override-qi43ln).ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #283549 !important;
  color: #ffffff !important;
  cursor: pointer;
}

.ant-select-selection-placeholder {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.5px;
  color: hsla(0, 0%, 100%, 0.7) !important;
}

:where(.css-dev-only-do-not-override-qi43ln).ant-select .ant-select-arrow {
  color: #50B848 !important;
  font-size: 17px !important;
}

h4.ant-typography.css-dev-only-do-not-override-qi43ln {
  color: #fff !important;
  font-weight: 100;
}

:where(.css-qi43ln).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
}

:where(.css-qi43ln).ant-select .ant-select-arrow {
  color: #50B848 !important;
  font-size: 17px !important;
}
