$theme-colors: (
        "primary": $primary-color,
);

@font-face {
  font-family: "Microgramma Becker Bold Extd";
  src: url("../fonts/microgramma-becker-bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Microgramma Becker Medium Extd";
  src: url("../fonts/microgramma-becker-med.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf");
}

